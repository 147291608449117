* {
    margin: 0;
    padding: 0;
}

body {
    font-family: inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    background-color: #f2f2f2;
}

html,
body,
#root {
    height: 100%;
    width: 100%;
}

.container::after {
    content: '';
    clear: both;
    display: table;
}
